<template>
  <v-dialog :value="true" />
</template>

<script>
export default {
    name: 'MarketingDialog',
    props: {
      objects: {
        type: Array, 
        default: () => []
      },
      callback: {
        type: Function,
        default: () => false
      }
    }
}
</script>